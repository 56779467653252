@import './theme/section.css';
@import './theme/page-header.css';
@import './theme/helper.css';
@import './theme/jost/stylesheet.css';
@import './theme/media.css';

$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-border-width: 0.25em !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;
$spinner-vertical-align: text-bottom;

$enable-prefers-reduced-motion-media-query: false;

@import 'bootstrap/scss/_spinners.scss';

body {
  background-color: #020542;
}

.btn-icon {
  padding-right: 8px;
  margin-right: 8px;
  display: inline-block;
  color: currentColor;
  opacity: 0.5;
  transition: 0.3s;
  text-decoration: none;
  font-size: 32px;
}

.btn-primary {
  color: #ffffff;
  border: 1px solid transparent;
  background-color: #d50b27;
}

.btn-outline {
  color: #1d1d1d;
  border: 1px solid rgba(29, 29, 29, 0.2);
  transition: 0.3s background-color;
  background: none;
  line-height: 1.25;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0px;
  font-size: 1.5rem;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
}

.btn-outline.btn-primary {
  color: #d50b27;
  border: 1px solid rgba(213, 11, 39, 0.2);
  transition: 0.3s background-color;
  background: none;
  line-height: 1.25;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0px;
  font-size: 1.5rem;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-outline.btn-primary {
  color: #cc132f;
  border-color: rgba(204, 19, 47, 0.2);
}

.btn-outline.btn-primary.active,
.btn-outline.btn-primary:active,
.btn-outline.btn-primary:focus,
.btn-outline.btn-primary:hover {
  background: #cc132f;
  border-color: #cc132f;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
}

.btn-outline.btn-primary:hover,
.btn-outline.btn-primary:focus {
  background: #d50b27;
  color: #ffffff;
  border-color: #d50b27;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2);
}

.btn-outline.btn-primary {
  color: #d50b27;
  border: 1px solid rgba(213, 11, 39, 0.2);
  transition: 0.3s background-color;
  background: none;
  line-height: 1.25;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0px;
  font-size: 1.5rem;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

textarea {
  overflow: auto;
  resize: vertical;
  min-height: 96px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

input[type='text'],
input[type='password'],
input[type='email'] {
  width: 100%;
  font-size: 1.125rem;
  max-width: 320px;
  min-height: 48px;
  background: transparent;
  color: currentColor;
  border: none;
  border-bottom: 2px solid currentColor;
  border-radius: 0;
  box-shadow: none;
}

input[type='text'].input-full,
input[type='password'].input-full,
input[type='email'].input-full {
  width: 100%;
  max-width: 640px;
}

.form-control-line,
.form-control-outline {
  display: block;
  width: 100%;
  border: 1px solid #ffffff;
  background: none;
  box-shadow: none;
  color: inherit;
  border-radius: 0px;
}

.form-control-outline.thick {
  border-width: 2px;
}

textarea.form-control {
  height: auto;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn {
  color: currentColor;
  border: 1px solid transparent;
  background-color: #d50b27;
  border-radius: 0;
  font-size: 1rem;
  line-height: 1.8;
}

.btn-line {
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  font-size: 1.5rem;
  border: none;
  background: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 1em;
  padding: 0;
}

.btns-action .btn {
  width: auto;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.contact-btns-action .btn-line {
  border-right: 2px solid currentColor;
  margin-right: 1rem;
  padding-right: 1.2rem;
}

.text-primary,
.media-number h4 {
  color: #ffffff !important;
}
.text-stroke.text-primary {
  -webkit-text-stroke: 1.5px #ffffff;
  color: transparent !important;
}

html,
body {
  margin: 0;
  padding: 0;
  color: #ffffff;
}

body {
  font-family: 'Noto Sans', 'Open Sans', 'Segoe UI', 'Opensans', 'Roboto',
    'Helvetica', -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
  font-size: 1.125rem;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

*,
::after,
::before {
  box-sizing: border-box;
}

ul,
li {
  list-style: none outside none;
  margin: 0;
}

.page-cover {
  width: 100%;
  position: fixed;
  height: 120%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.video-bg {
  grid-area: var(--fullGrid);
  min-width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.video-bg::-webkit-media-controls {
  display: none !important;
}

.page-header-drawer {
  justify-content: flex-start;
  z-index: 2;
  position: absolute;
  width: 100%;
}

.page-header-drawer .navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.page-header-drawer .navbar-brand .logo img {
  height: 40px;
  width: auto;
  margin-right: 16px;
}

.page-header-drawer .navbar-brand .text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: currentColor;
  font-size: 1.125rem;
  margin-left: 0;
  margin-right: 8px;
}

.page-header-drawer .navbar-brand .text .line {
  display: block;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  font-size: 1.75rem;
  line-height: 1;
}

.page-main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.section.section-center,
.section.section-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .page-header-drawer {
    padding: 0;
  }
  .page-header-drawer.menu-right .navbar-brand {
    left: 48px;
  }
  .page-header-drawer .navbar-brand .logo img {
    height: 40px;
  }
  .section.section-center,
  .section.section-centered {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .logo {
    margin-left: 10px;
  }
}

@media (min-width: 1024px) {
  .page-header-drawer .navbar-brand {
    margin-top: -8px;
    position: fixed;
    left: 120px;
    top: 64px;
    z-index: 20;
  }
}

*:focus {
  outline: none;
}

.flex-even-8 {
  flex: 1;
}

.text-dark {
  color: #343a40 !important;
}

.bg-white {
  background-color: #fff !important;
}

.no-text {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.portfolio-desc {
  line-height: 1.6;
}

.portfolio-site {
  margin-top: 1rem !important;
  font-size: 1rem;
  text-transform: uppercase;
}

.portfolio-site a {
  font-size: 1.2rem;
  color: #07c1fc;
  text-transform: lowercase;
}
