/* media number */
.media-number {
  position: relative;
  display: block;
  color: currentColor;
  text-align: left;
  padding-right: 2rem;
  margin-top: 2rem; }
  .media-number:hover {
    color: currentColor;
    text-decoration: none; }
  .media-number h4 {
    font-family: "Jost", "Glacial Indifference", "Montserrat", "Open Sans", sans-serif;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1 !important;
    color: #D50B27; }
    @media (min-width: 1200px) {
      .media-number h4 {
        font-size: 6rem; } }
