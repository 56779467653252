/* 4 Sections */
/* 4.1 Default layout settings */
/* 4.1.1 Section layout */
/* a Section Background / Cover / .section-cover-full, .section-cover */
.section {
  position: relative;
}

.section-cover-image {
  min-height: 200px;
}
.section-cover-image.section {
  overflow: unset !important;
}
@media (min-width: 1024px) {
  .section-cover-image {
    min-height: 360px;
  }
}
@media (min-width: 1600px) {
  .section-cover-image {
    min-height: 400px;
  }
}
@media (min-width: 1024px) {
  .section-cover-image.cover-small {
    min-height: 320px;
  }
}
@media (min-width: 1600px) {
  .section-cover-image.cover-small {
    min-height: 360px;
  }
}

/* .section-cover-half-left {
  position: relative;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  background: #1d1d1d;
  background-size: cover;
  background-position: center;
} */
/* @media (min-width: 576px) {
  .section-cover-half-left {
    position: absolute;
    width: 50%;
  }
} */

/* .section-cover-half-right {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: auto;
  right: auto;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 1104px;
  background: #1d1d1d;
  background-size: cover;
  background-position: center;
}
.section-cover-half-right img {
  max-width: 100%;
} */

/* .section-cover-tier {
  position: relative;
  left: 0;
  width: 100%;
  top: -32px;
  min-height: 200px;
  background: #1d1d1d;
  background-size: cover;
  background-position: center;
  transition: 0.3s ease-in-out;
}
@media (min-width: 1024px) {
  .section-cover-tier {
    top: 0;
    position: fixed;
    width: 33.33%;
    height: 100vh;
    transition-delay: 0.6s;
  }
}

@media (min-width: 768px) {
  .section.active .section-cover-tier {
    -webkit-transform: none;
    transform: none;
  }
} */

.section-cover-column {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
}
@media (min-width: 768px) {
  .section-cover-column {
    margin-left: -16px;
    margin-right: -16px;
  }
}
@media (min-width: 1024px) {
  .section-cover-column {
    /* margin-left: -24px;
    margin-right: -24px; */
    margin-left: 0px;
    margin-right: 0px;
  }
}
.section-cover-column .img1,
.section-cover-column .img2,
.section-cover-column .img3 {
  position: relative;
  height: 100%;
  width: 33.33333%;
}
.section-cover-column .img1 .color-block,
.section-cover-column .img1 .img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 8px;
}
.section-cover-column .img2 .color-block,
.section-cover-column .img2 .img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 8px;
}
.section-cover-column .img3 .color-block,
.section-cover-column .img3 .img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 0px;
}
@media (min-width: 768px) {
  .section-cover-column .img1 .color-block,
  .section-cover-column .img1 .img {
    left: 0;
    right: 16px;
  }
  .section-cover-column .img2 .color-block,
  .section-cover-column .img2 .img {
    left: 16px;
    right: 16px;
  }
  .section-cover-column .img3 .color-block,
  .section-cover-column .img3 .img {
    left: 16px;
    right: 0;
  }
}
@media (min-width: 1024px) {
  .section-cover-column .img1 .color-block,
  .section-cover-column .img1 .img {
    left: 0;
    right: 24px;
  }
  .section-cover-column .img2 .color-block,
  .section-cover-column .img2 .img {
    left: 24px;
    right: 24px;
  }
  .section-cover-column .img3 .color-block,
  .section-cover-column .img3 .img {
    left: 24px;
    right: 0;
  }
}

/* .section-cover,
.section-cover-full {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  min-height: 200px;
  background-size: cover;
  background-position: center;
  transition: 0.3s ease-in-out;
  z-index: -1;
}
.section-cover .img,
.section-cover-full .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
}
@media (min-width: 768px) {
  .section-cover.bg-pos-left,
  .section-cover-full.bg-pos-left {
    background-position: left center !important;
  }
  .section-cover.bg-pos-right,
  .section-cover-full.bg-pos-right {
    background-position: right center !important;
  }
}
@media (min-width: 1024px) {
  .section-cover,
  .section-cover-full {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    transition-delay: 0.6s;
  }
}
.section-cover .cover-content,
.section-cover-full .cover-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.section-cover.mask-black-light:before,
.section-cover-full.mask-black-light:before {
  z-index: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

@media (min-width: 1024px) {
  .section-cover.cover-left {
    width: 66.66667%;
  }
}

@media (min-width: 1024px) {
  .section-cover.cover-right {
    width: 66.66667%;
    left: auto;
    right: 0;
  }
}

@media (max-width: 576px) {
  .section-gallery .section-cover-full {
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: none;
  }
} */

/* .section-cover.mask-black:before,
.section-cover-full.mask-black:before {
  z-index: 1;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
} */

/* .cover-content {
  position: relative;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  padding: 64px 16px;
  padding-top: 120px;
  color: #ffffff;
}
@media (min-width: 576px) {
  .cover-content {
    padding-left: 64px;
    padding-bottom: 64px;
    padding-right: 40px;
  }
}
.cover-content p:last-child {
  margin-bottom: 0;
} */

/* .cover-margin {
  margin-top: 80px;
  width: 100%;
}
@media (min-width: 1024px) {
  .cover-margin {
    position: relative;
    margin-top: 128px;
  }
} */

/* b Section Footer / .scrolldown / .section-footer */
.scrolldown {
  position: absolute;
  bottom: 0;
  top: auto;
  left: auto;
  right: 56px;
  margin-bottom: 54px;
  font-family: 'Noto Sans', 'Open Sans', 'Segoe UI', 'Opensans', 'Roboto',
    'Helvetica', -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
  z-index: 2;
  background: transparent;
  display: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
}
@media (min-width: 1024px) {
  .scrolldown {
    display: block;
  }
}
@media (max-height: 480px) {
  .scrolldown {
    display: none;
  }
}
.scrolldown a {
  margin-left: 0;
  position: relative;
  display: block;
  font-size: 1.125rem;
  line-height: 1;
  transition: 0.3s;
  background: transparent;
  border: none;
  padding-bottom: 2px;
  padding-right: 56px;
  color: currentColor;
}
.scrolldown a .txt {
  position: relative;
  min-width: 100px;
  text-align: center;
  opacity: 0.5;
  transition: 0.3s;
}
.scrolldown a:before,
.scrolldown a:after {
  color: #d50b27;
}
.scrolldown a:before {
  position: absolute;
  content: '';
  height: 2px;
  width: 32px;
  right: 0.75rem;
  top: 0.4em;
  background: currentColor;
}
.scrolldown a:after {
  position: absolute;
  content: '';
  height: 1.5rem;
  width: 1.5rem;
  right: -1px;
  top: 1px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 0.4em;
  border: 2px solid currentColor;
  border-radius: 80px;
}
.scrolldown a:hover {
  color: currentColor;
}
.scrolldown a:hover .txt {
  opacity: 1;
}

/* .section.content-white {
  color: #ffffff;
}
.section.content-white a::after,
.section.content-white a::before {
  color: #ffffff;
} */

/* .section.content-black {
  color: #1d1d1d;
} */

.no-footer .scrolldown {
  display: none !important;
}

.section .scrolldown {
  transition: 0.6s;
}

.section.active .scrolldown {
  opacity: 1;
  visibility: visible;
}

.section-footer .navbar-brand {
  display: flex;
  flex-direction: row;
  padding: 0;
  color: currentColor;
  margin-bottom: 8px;
}
.section-footer .navbar-brand .logo img {
  height: 40px;
  width: auto;
}
@media (min-width: 768px) {
  .section-footer .navbar-brand .logo img {
    height: 56px;
  }
}
.section-footer .navbar-brand .text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: currentColor;
  font-size: 1.125rem;
  margin-left: 16px;
  margin-right: 8px;
}
.section-footer .navbar-brand .text .line {
  display: block;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  font-size: 1.25rem;
}
.section-footer .navbar-brand .text .line.sub {
  font-family: 'Noto Sans', 'Open Sans', 'Segoe UI', 'Opensans', 'Roboto',
    'Helvetica', -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
  font-size: 0.875rem;
  opacity: 0.5;
}

/* c section main content layout / .section .section-wrapper */
.btns-action {
  margin-top: 40px;
}
@media (min-width: 576px) {
  .btns-action .btn {
    width: auto;
  }
}

.section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* .section.content-white {
  color: #ffffff;
} */
.section.content-black {
  color: #1d1d1d;
}
@media (max-width: 576px) {
  .section {
    display: block;
  }
}
@media (max-width: 768px) {
  .section {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .section {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .section {
    padding: 0;
  }
}
@media (max-height: 480px) {
  .section {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.section.section-center,
.section.section-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .section.section-center,
  .section.section-centered {
    padding-top: 0;
  }
}
.section.section-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .section.section-bottom .section-wrapper.with-margin {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.section h4,
.section h5 {
  line-height: 1.6;
}
.section .padding-top {
  padding-top: 32px;
}
@media (min-width: 768px) {
  .section .padding-top {
    padding-top: 64px;
  }
}
@media (min-width: 1024px) {
  .section .padding-top {
    padding-top: 80px;
  }
}
.section .padding-bottom {
  padding-bottom: 32px;
}
@media (min-width: 768px) {
  .section .padding-bottom {
    padding-bottom: 64px;
  }
}
@media (min-width: 1024px) {
  .section .padding-bottom {
    padding-bottom: 80px;
  }
}
.section .slide {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}
.section.no-slide-arrows .fp-controlArrow {
  visibility: hidden;
}
.section .section-wrapper {
  width: 100%;
  max-width: 1080px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}
.section .section-wrapper.largewidth {
  max-width: 1080px;
}
@media (min-width: 768px) {
  .section .section-wrapper .scrollable {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) {
  .section .section-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1600px) {
  .section .section-wrapper {
    max-width: 1200px;
  }
}
.section .section-wrapper.fullwidth {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1600px) {
  .section .section-wrapper.fullwidth {
    max-width: none;
  }
}
.section .section-wrapper.with-margin {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  .section .section-wrapper.with-margin {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1024px) {
  .section .section-wrapper.with-margin {
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 128px;
    padding-bottom: 128px;
  }
}
@media (min-width: 1024px) {
  .section .section-wrapper.with-margin.equal {
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 128px;
    padding-bottom: 128px;
  }
}
@media (min-width: 1200px) {
  .section .section-wrapper.with-margin.equal {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1200px) {
  .section .section-wrapper.with-margin {
    padding-left: 48px;
    padding-right: 48px;
  }
}
.section .section-wrapper.small-margin {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  .section .section-wrapper.small-margin {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1024px) {
  .section .section-wrapper.small-margin {
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.section .section-wrapper.margin-tb {
  padding-top: 48px;
  padding-bottom: 48px;
}
@media (min-width: 1024px) {
  .section .section-wrapper.margin-tb {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.section .align-items-bottom {
  display: flex;
  align-items: flex-end;
}

.section-gallery-item {
  padding-top: 96px;
}
@media (min-width: 768px) {
  .section-gallery-item {
    padding-top: 120px;
  }
}

.section-item.item-detail .section-wrapper {
  padding-bottom: 48px;
}
@media (min-width: 1024px) {
  .section-item.item-detail .section-wrapper {
    padding-bottom: 64px;
  }
}

.section-item.item-detail .article {
  margin-top: 64px;
}

@media screen and (min-width: 768px) {
  .section-item.item-detail .btns-action {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media screen and (min-width: 1200px) {
  .section-item.item-detail .btns-action {
    padding: 0;
  }
}

.section-item.item-cover .section-wrapper {
  padding-top: 48px;
  padding-bottom: 48px;
}
@media (min-width: 1024px) {
  .section-item.item-cover .section-wrapper {
    padding-top: 120px;
    padding-bottom: 64px;
  }
}

@media screen and (min-width: 768px) {
  .section-item.item-cover .section-content {
    padding-top: 128px;
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media screen and (min-width: 1200px) {
  .section-item.item-cover .section-content {
    padding: 0;
  }
}

.section-slider {
  padding: 0;
}

.section-gallery {
  background: #1d1d1d;
}

.section-demo {
  min-height: 400px;
  min-height: 70vh;
}

.section .fp-scrollable {
  min-width: 99vw;
}

/* 4.1.2 Section background / .section-bg */
.section-bg {
  position: absolute;
  z-index: 0;
}
.section-bg .img-frame {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 480px;
  width: 75vw;
  height: 75vh;
}
@media (min-width: 768px) {
  .section-bg .img-frame {
    font-size: 6rem;
    width: 60vw;
    height: 60vh;
  }
}
@media (min-width: 1024px) {
  .section-bg .img-frame {
    font-size: 6rem;
    width: 33vw;
    height: 80vh;
  }
}
.section-bg .img-frame .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* 4.1.3 Section title / .section-title */
.section-title {
  margin: 0 auto;
}
.section-title.title-centered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12rem;
  margin-bottom: 0;
  font-weight: 600;
  transition: 1.2s;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  z-index: 0;
  opacity: 0.2;
}
.section-title.title-centered h2 {
  font-size: 4rem;
  text-shadow: none;
  font-family: inherit;
}
@media (min-width: 768px) {
  .section-title.title-centered h2 {
    font-size: 6rem;
  }
}
@media (min-width: 1024px) {
  .section-title.title-centered h2 {
    font-size: 12rem;
  }
}
.section-title.title-top {
  position: absolute;
  top: 88px;
  width: 100%;
  line-height: 1rem;
  font-weight: 600;
  transition: 1.2s;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  z-index: 0;
  opacity: 0;
  opacity: 0.05;
  top: auto;
  /* left: auto; */
  /* margin-left: 16px; */
  margin-left: 0;
}
@media (min-width: 768px) {
  .section-title.title-top {
    /* margin-left: 48px; */
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .section-title.title-top {
    opacity: 0.2;
    /* padding-right: 96px; */
    padding-left: 0;
    top: 256px;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@media (min-width: 1200px) {
  .section-title.title-top {
    padding-right: 0;
  }
}
.section-title.title-top h2 {
  font-size: 4rem;
  text-shadow: none;
  font-family: inherit;
  margin-bottom: 0.5rem;
  line-height: 0;
}
@media (min-width: 768px) {
  .section-title.title-top h2 {
    font-size: 8rem;
  }
}
@media (min-width: 1024px) {
  .section-title.title-top h2 {
    font-size: 12rem;
  }
}

.fullpage-container .section-title .title-bg {
  opacity: 0;
  transition-delay: 0s;
}

.section.active .section-title .title-bg {
  opacity: 0.1;
  transition-delay: 0.6s;
}

/* 4.1.4 Section content / .seciton-content */
@media (min-width: 768px) {
  .section-content.reduced {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.section-content .section-content-header {
  margin-bottom: 64px;
}
.section-content .section-content-header p:last-child {
  margin-bottom: 0;
}
.section-content .section-content-header .title-desc {
  margin-bottom: 0;
}
.section-content .section-content-header.decor {
  padding-bottom: 48px;
  border-bottom: 2px solid currentColor;
}

.section-content .title-desc {
  margin-bottom: 2rem;
  position: relative;
}
@media (min-width: 768px) {
  .section-content .title-desc {
    margin-bottom: 1rem;
  }
}
.section-content .title-desc .display-title {
  margin-bottom: 24px;
}
.section-content .title-desc .display-title.decor {
  position: relative;
  padding-top: 1em;
}
.section-content .title-desc .display-title.decor:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 128px;
  height: auto;
  border-bottom: 2px solid currentColor;
}
@media (min-width: 768px) {
  .section-content .title-desc .desc-abs {
    position: absolute;
    left: 0;
    right: 0;
  }
}
.section-content .title-desc.title-margin {
  margin-bottom: 4rem;
}
.section-content .title-desc h2 {
  margin-bottom: 2rem;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
}

.section-content .title-decor {
  border-bottom: 1px solid currentColor;
  opacity: 0.2;
  width: 100%;
}

.section-content .illustr img {
  width: 100%;
}
.section-content .illustr img.portrait {
  max-height: 66vh;
  width: auto;
}

.section-content .illustr-legend {
  position: relative;
}
.section-content .illustr-legend img,
.section-content .illustr-legend .img {
  width: 50%;
  max-width: 480px;
}
.section-content .illustr-legend .legend {
  text-align: left;
}
@media (min-width: 768px) {
  .section-content .illustr-legend .legend {
    position: absolute;
    left: 75%;
    width: 25%;
    top: 10%;
  }
}
.section-content .illustr-legend .legend hr {
  width: 50%;
  margin: 0.25rem 0;
  border-top: 2px solid #d50b27;
}
.section-content .illustr-legend .legend h4 {
  font-weight: bold;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .section-content .illustr-legend .legend.legend-left {
    left: 0%;
  }
}
.section-content .illustr-legend .legend.text-right hr {
  margin-left: 50%;
}

.section-content .illustr-center {
  position: relative;
  width: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.section-content .illustr-center .img,
.section-content .illustr-center img {
  width: 100%;
}

.section-content hr {
  width: 50%;
  margin: 0.25rem 0;
  border-top: 2px solid #d50b27;
}

.section-content h4 {
  display: inline-block;
}
.section-content h4 strong {
  color: #d50b27;
}

.section-content ul {
  padding-left: 1rem;
}
.section-content ul li {
  list-style: disc;
}
.section-content ul.list-primary {
  padding-left: 0;
}
.section-content ul.list-primary > li {
  position: relative;
  padding-left: 1.5em;
  list-style: none;
}
.section-content ul.list-primary > li:before {
  position: absolute;
  border-radius: 2rem;
  background: #d50b27;
  width: 1rem;
  height: 1rem;
  left: 0;
  top: 0.25em;
  content: '';
}
.section-content ul.list-secondary {
  padding-left: 0;
}
.section-content ul.list-secondary > li {
  position: relative;
  padding-left: 1.5em;
  list-style: none;
}
.section-content ul.list-secondary > li:before {
  position: absolute;
  border-radius: 2rem;
  background: #3f51b5;
  width: 1rem;
  height: 1rem;
  left: 0;
  top: 0.25em;
  content: '';
}
.section-content ul.list-white {
  padding-left: 0;
}
.section-content ul.list-white > li {
  position: relative;
  padding-left: 1.5em;
  list-style: none;
}
.section-content ul.list-white > li:before {
  position: absolute;
  border-radius: 2rem;
  background: #ffffff;
  width: 1rem;
  height: 1rem;
  left: 0;
  top: 0.25em;
  content: '';
}
.section-content ul.list-black {
  padding-left: 0;
}
.section-content ul.list-black > li {
  position: relative;
  padding-left: 1.5em;
  list-style: none;
}
.section-content ul.list-black > li:before {
  position: absolute;
  border-radius: 2rem;
  background: #1d1d1d;
  width: 1rem;
  height: 1rem;
  left: 0;
  top: 0.25em;
  content: '';
}

.section-content h2.border-primary,
.section-content h3.border-primary,
.section-content h4.border-primary,
.section-content h5.border-primary,
.section-content p.border-primary {
  position: relative;
  padding-left: 1.5em;
}
.section-content h2.border-primary:before,
.section-content h3.border-primary:before,
.section-content h4.border-primary:before,
.section-content h5.border-primary:before,
.section-content p.border-primary:before {
  position: absolute;
  border-top: 2px solid #d50b27;
  width: 1em;
  height: 0;
  left: 0;
  top: 0.5em;
  content: '';
}

.section-content h2.border-secondary,
.section-content h3.border-secondary,
.section-content h4.border-secondary,
.section-content h5.border-secondary,
.section-content p.border-secondary {
  position: relative;
  padding-left: 1.5em;
}
.section-content h2.border-secondary:before,
.section-content h3.border-secondary:before,
.section-content h4.border-secondary:before,
.section-content h5.border-secondary:before,
.section-content p.border-secondary:before {
  position: absolute;
  border-top: 2px solid #3f51b5;
  width: 1em;
  height: 0;
  left: 0;
  top: 0.5em;
  content: '';
}

.section-content h2.border-white,
.section-content h3.border-white,
.section-content h4.border-white,
.section-content h5.border-white,
.section-content p.border-white {
  position: relative;
  padding-left: 1.5em;
}
.section-content h2.border-white:before,
.section-content h3.border-white:before,
.section-content h4.border-white:before,
.section-content h5.border-white:before,
.section-content p.border-white:before {
  position: absolute;
  border-top: 2px solid #ffffff;
  width: 1em;
  height: 0;
  left: 0;
  top: 0.5em;
  content: '';
}

.section-content h2.border-black,
.section-content h3.border-black,
.section-content h4.border-black,
.section-content h5.border-black,
.section-content p.border-black {
  position: relative;
  padding-left: 1.5em;
}
.section-content h2.border-black:before,
.section-content h3.border-black:before,
.section-content h4.border-black:before,
.section-content h5.border-black:before,
.section-content p.border-black:before {
  position: absolute;
  border-top: 2px solid #1d1d1d;
  width: 1em;
  height: 0;
  left: 0;
  top: 0.5em;
  content: '';
}

.list-line-margin .item {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .list-line-margin .item {
    margin-bottom: 4rem;
  }
}
.list-line-margin .item:last-child {
  margin-bottom: 0;
}

.slide.slide-gallery {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide.slide-gallery .section-cover-full {
  position: absolute;
  height: 100%;
}

.section-gallery {
  padding: 0;
}

.row .align-self-end .section-content .title-desc {
  margin-bottom: 0;
}

.row .align-self-end .section-content p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .fp-scrollable {
    height: auto !important;
  }
  .fp-scroller {
    height: auto !important;
  }
}

/* 4.1.5 Section content / .seciton-contact */
.contact-btns-action .btn-line {
  border-right: 2px solid currentColor;
  margin-right: 1rem;
  padding-right: 1.2rem;
}
.contact-btns-action .btn-line:last-child {
  border-color: transparent;
}

.contact-btns-action .btn.gone {
  display: none;
}

.contact-slider {
  overflow: hidden;
}

/* 4.1.6 Animation */
@media (min-width: 1024px) {
  .section .anim.translateUp {
    -webkit-transform: translateY(48px);
    transform: translateY(48px);
    transition: 0.3s ease-in-out;
  }
  .section.active .anim.translateUp,
  .section.section-anim .anim.translateUp {
    transition-delay: 0.3s;
    -webkit-transform: none;
    transform: none;
  }
  .section .anim.translateDown {
    -webkit-transform: translateY(-48px);
    transform: translateY(-48px);
    transition: 0.3s ease-in-out;
  }
  .section.active .anim.translateDown,
  .section.section-anim .anim.translateDown {
    transition-delay: 0.3s;
    -webkit-transform: none;
    transform: none;
  }
}

@media (min-width: 1024px) {
  .scrollpage-container .section-home .anim .anim-wrapper,
  .scroll-anim .section .anim .anim-wrapper {
    overflow: hidden;
    display: block;
  }
  .scrollpage-container .section-home .anim .anim-1,
  .scrollpage-container .section-home .anim .anim-2,
  .scrollpage-container .section-home .anim .anim-3,
  .scrollpage-container .section-home .anim .anim-4,
  .scrollpage-container .section-home .anim .anim-5,
  .scroll-anim .section .anim .anim-1,
  .scroll-anim .section .anim .anim-2,
  .scroll-anim .section .anim .anim-3,
  .scroll-anim .section .anim .anim-4,
  .scroll-anim .section .anim .anim-5 {
    transition: 0.3s;
    transition-delay: 0s;
    position: relative;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  .scrollpage-container .section-home .anim .zoomout-1,
  .scroll-anim .section .anim .zoomout-1 {
    transition: 0.6s;
    transition-delay: 0s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
  }
  .scrollpage-container .section-home .anim .anim-list .item,
  .scroll-anim .section .anim .anim-list .item {
    opacity: 0;
    -webkit-transform: translateY(16px);
    transform: translateY(16px);
    transition: 0.3s;
  }
  .scrollpage-container .section-home .anim .fade-2,
  .scrollpage-container .section-home .anim .fade-3,
  .scrollpage-container .section-home .anim .fade-4,
  .scrollpage-container .section-home .anim .fade-1,
  .scroll-anim .section .anim .fade-2,
  .scroll-anim .section .anim .fade-3,
  .scroll-anim .section .anim .fade-4,
  .scroll-anim .section .anim .fade-1 {
    opacity: 0;
    transition: 0.9s;
    transition-delay: 0s;
  }
  .scrollpage-container .section-home .anim .anim-blur,
  .scroll-anim .section .anim .anim-blur {
    -webkit-filter: blur(50px);
    filter: blur(50px);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    transition: 0.6s;
    transition-delay: 0s;
  }
  .scrollpage-container .section-home.active .cover-anim,
  .scroll-anim .section.active .cover-anim {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .scrollpage-container .section-home.active .side-anim,
  .scroll-anim .section.active .side-anim {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .scrollpage-container .section-home.active .decor-anim::before,
  .scrollpage-container .section-home.active .decor-anim::after,
  .scroll-anim .section.active .decor-anim::before,
  .scroll-anim .section.active .decor-anim::after {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .scrollpage-container .section-home.active .anim .anim-1,
  .scroll-anim .section.active .anim .anim-1 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.15s;
    opacity: 1;
  }
  .scrollpage-container .section-home.active .anim .anim-2,
  .scroll-anim .section.active .anim .anim-2 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.25s;
    opacity: 1;
  }
  .scrollpage-container .section-home.active .anim .anim-3,
  .scroll-anim .section.active .anim .anim-3 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.35s;
    opacity: 1;
  }
  .scrollpage-container .section-home.active .anim .anim-4,
  .scroll-anim .section.active .anim .anim-4 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.45s;
  }
  .scrollpage-container .section-home.active .anim .anim-5,
  .scroll-anim .section.active .anim .anim-5 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.6s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(1),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(1) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.5s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(2),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(2) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.6s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(3),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(3) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.7s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(4),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(4) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.8s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(5),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(5) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.9s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(6),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(6) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(7),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(7) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.1s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(8),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(8) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.2s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(9),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(9) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.3s;
  }
  .scrollpage-container
    .section-home.active
    .anim
    .anim-list
    .item:nth-child(10),
  .scroll-anim .section.active .anim .anim-list .item:nth-child(10) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.4s;
  }
  .scrollpage-container .section-home.active .anim .zoomout-1,
  .scroll-anim .section.active .anim .zoomout-1 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
    opacity: 1;
  }
  .scrollpage-container .section-home.active .anim .fade-1,
  .scroll-anim .section.active .anim .fade-1 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .scrollpage-container .section-home.active .anim .fade-2,
  .scroll-anim .section.active .anim .fade-2 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.5s;
  }
  .scrollpage-container .section-home.active .anim .fade-3,
  .scroll-anim .section.active .anim .fade-3 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.7s;
  }
  .scrollpage-container .section-home.active .anim .fade-4,
  .scroll-anim .section.active .anim .fade-4 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.9s;
  }
  .scrollpage-container .section-home.active .anim .anim-blur,
  .scroll-anim .section.active .anim .anim-blur {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.9s;
  }
}

@media (min-width: 1024px) {
  .fullpage-container .section .cover-anim {
    opacity: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    transition: 0.3s;
  }
  .fullpage-container .section .side-anim {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    transition: 0.3s;
  }
  .fullpage-container .section .decor-anim::before {
    opacity: 0;
    transition: 0.3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .fullpage-container .section .decor-anim::after {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition: 0.3s;
  }
  .fullpage-container .section .anim .anim-wrapper {
    overflow: hidden;
    display: block;
  }
  .fullpage-container .section .anim .anim-1,
  .fullpage-container .section .anim .anim-2,
  .fullpage-container .section .anim .anim-3,
  .fullpage-container .section .anim .anim-4,
  .fullpage-container .section .anim .anim-5 {
    transition: 0.3s;
    transition-delay: 0s;
    position: relative;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  .fullpage-container .section .anim .zoomout-1 {
    transition: 0.6s;
    transition-delay: 0s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
  }
  .fullpage-container .section .anim .anim-list .item {
    opacity: 0;
    -webkit-transform: translateY(16px);
    transform: translateY(16px);
    transition: 0.3s;
  }
  .fullpage-container .section .anim .fade-2,
  .fullpage-container .section .anim .fade-3,
  .fullpage-container .section .anim .fade-4,
  .fullpage-container .section .anim .fade-1 {
    opacity: 0;
    transition: 0.6s;
    transition-delay: 0s;
  }
  .fullpage-container .section .anim .anim-blur {
    -webkit-filter: blur(50px);
    filter: blur(50px);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    transition: 0.6s;
    transition-delay: 0s;
  }
  .fullpage-container .section.active.fp-completely .cover-anim {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .fullpage-container .section.active.fp-completely .side-anim {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .fullpage-container .section.active.fp-completely .decor-anim::before,
  .fullpage-container .section.active.fp-completely .decor-anim::after {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .fullpage-container .section.active.fp-completely .anim .anim-1 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.15s;
    opacity: 1;
  }
  .fullpage-container .section.active.fp-completely .anim .anim-2 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.25s;
    opacity: 1;
  }
  .fullpage-container .section.active.fp-completely .anim .anim-3 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.35s;
    opacity: 1;
  }
  .fullpage-container .section.active.fp-completely .anim .anim-4 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.45s;
  }
  .fullpage-container .section.active.fp-completely .anim .anim-5 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.6s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(1) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.5s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(2) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.6s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(3) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.7s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(4) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.8s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(5) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.9s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(6) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(7) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.1s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(8) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.2s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(9) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.3s;
  }
  .fullpage-container
    .section.active.fp-completely
    .anim
    .anim-list
    .item:nth-child(10) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.4s;
  }
  .fullpage-container .section.active.fp-completely .anim .zoomout-1 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
    opacity: 1;
  }
  .fullpage-container .section.active.fp-completely .anim .fade-1 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .fullpage-container .section.active.fp-completely .anim .fade-2 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.5s;
  }
  .fullpage-container .section.active.fp-completely .anim .fade-3 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.7s;
  }
  .fullpage-container .section.active.fp-completely .anim .fade-4 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.9s;
  }
  .fullpage-container .section.active.fp-completely .anim .anim-blur {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.9s;
  }
}

/* slide animation */
@media (min-width: 768px) {
  .section .slide .anim .anim-wrapper {
    overflow: hidden;
    display: block;
  }
  .section .slide .anim .anim-1,
  .section .slide .anim .anim-2,
  .section .slide .anim .anim-3,
  .section .slide .anim .anim-4,
  .section .slide .anim .anim-5 {
    transition: 0.3s;
    transition-delay: 0s;
    position: relative;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  .section .slide .anim .zoomout-1 {
    transition: 0.6s;
    transition-delay: 0s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
  }
  .section .slide .anim .anim-list .item {
    opacity: 0;
    -webkit-transform: translateY(16px);
    transform: translateY(16px);
    transition: 0.3s;
  }
  .section .slide .anim .fade-2,
  .section .slide .anim .fade-3,
  .section .slide .anim .fade-4,
  .section .slide .anim .fade-1 {
    opacity: 0;
    transition: 0.6s;
    transition-delay: 0s;
  }
  .section .slide .anim .anim-blur {
    -webkit-filter: blur(50px);
    filter: blur(50px);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    transition: 0.6s;
    transition-delay: 0s;
  }
  .section .slide.active .anim .anim-1,
  .section .slide.section-anim .anim .anim-1 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.15s;
    opacity: 1;
  }
  .section .slide.active .anim .anim-2,
  .section .slide.section-anim .anim .anim-2 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.25s;
    opacity: 1;
  }
  .section .slide.active .anim .anim-3,
  .section .slide.section-anim .anim .anim-3 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.35s;
    opacity: 1;
  }
  .section .slide.active .anim .anim-4,
  .section .slide.section-anim .anim .anim-4 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.45s;
  }
  .section .slide.active .anim .anim-5,
  .section .slide.section-anim .anim .anim-5 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.6s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(1),
  .section .slide.section-anim .anim .anim-list .item:nth-child(1) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.8s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(2),
  .section .slide.section-anim .anim .anim-list .item:nth-child(2) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.95s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(3),
  .section .slide.section-anim .anim .anim-list .item:nth-child(3) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.1s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(4),
  .section .slide.section-anim .anim .anim-list .item:nth-child(4) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.25s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(5),
  .section .slide.section-anim .anim .anim-list .item:nth-child(5) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.4s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(6),
  .section .slide.section-anim .anim .anim-list .item:nth-child(6) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.55s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(7),
  .section .slide.section-anim .anim .anim-list .item:nth-child(7) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.7s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(8),
  .section .slide.section-anim .anim .anim-list .item:nth-child(8) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 1.85s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(9),
  .section .slide.section-anim .anim .anim-list .item:nth-child(9) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 2s;
  }
  .section .slide.active .anim .anim-list .item:nth-child(10),
  .section .slide.section-anim .anim .anim-list .item:nth-child(10) {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 2.15s;
  }
  .section .slide.active .anim .zoomout-1,
  .section .slide.section-anim .anim .zoomout-1 {
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
    opacity: 1;
  }
  .section .slide.active .anim .fade-1,
  .section .slide.section-anim .anim .fade-1 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.3s;
  }
  .section .slide.active .anim .fade-2,
  .section .slide.section-anim .anim .fade-2 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.5s;
  }
  .section .slide.active .anim .fade-3,
  .section .slide.section-anim .anim .fade-3 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.7s;
  }
  .section .slide.active .anim .fade-4,
  .section .slide.section-anim .anim .fade-4 {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.9s;
  }
  .section .slide.active .anim .anim-blur,
  .section .slide.section-anim .anim .anim-blur {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    transition-delay: 0.9s;
  }
}

/* 4.2 Home section layout / section-home */
.section-home {
  min-height: 640px;
  min-height: 100vh;
  padding: 0;
}
.section-home .section-wrapper {
  width: 100%;
  text-align: center;
}
.section-home .section-wrapper.twoside {
  text-align: left;
  max-width: 1020px;
}
@media (min-width: 1600px) {
  .section-home .section-wrapper.twoside {
    max-width: 1200px;
  }
}
.section-home .section-wrapper .fit-screen-sm {
  min-height: 512px;
}
@media (min-width: 768px) {
  .section-home .section-wrapper .fit-screen-sm {
    min-height: auto;
  }
}
.section-home .section-wrapper.padding-top {
  padding-top: 160px;
}
@media (min-width: 768px) {
  .section-home .section-wrapper.with-margin {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.section-home .btns-action {
  margin-bottom: -8px;
}
.section-home .home-title {
  font-size: 4rem;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
@media (min-width: 1024px) {
  .section-home .home-title {
    font-size: 4.5rem;
  }
}
.section-home .section-content .bordered {
  border-bottom: 2px solid currentColor;
}
.section-home .section-content .title-desc {
  margin-bottom: 1.5rem;
}
.section-home .section-content .illustr img {
  width: 100%;
}
.section-home .section-content .illustr img.portrait {
  max-height: 400px;
  width: auto;
}
@media (min-width: 768px) {
  .section-home .section-content .illustr img.portrait {
    max-height: 66vh;
    width: auto;
  }
}
.section-home .section-content img.logo {
  height: 96px;
  margin: 0.75rem 0;
  max-width: 100%;
  width: auto;
}
@media (min-width: 576px) {
  .section-home .section-content img.logo {
    height: 128px;
  }
}
@media (min-width: 768px) {
  .section-home .section-content img.logo {
    height: 160px;
  }
}

.btns-bordered {
  border: 1px solid currentColor;
  border-left: none;
  border-right: none;
}
.btns-bordered .btn {
  margin: 0 8px;
}

.section-home .section-content {
  position: relative;
}

.section-home .text-bg-wrapper {
  z-index: -1;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.section-home .text-bg-wrapper.right {
  left: auto;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-home .col-wrapper {
  position: relative;
}

.section-home .big-text {
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  font-size: 16rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: -0.12em;
}

.section-home .image-bg-wrapper {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 33%;
  top: 50%;
  max-width: 640px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 1024px) {
  .section-home .image-bg-wrapper {
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
}
.section-home .image-bg-wrapper .col-wrapper {
  width: 100%;
}
.section-home .image-bg-wrapper img {
  width: 100%;
}
.section-home .image-bg-wrapper .img {
  width: 100%;
  height: 300px;
}
@media (min-width: 1024px) {
  .section-home .image-bg-wrapper .img {
    height: 70vh;
  }
}

.section-home.section-center .home-title {
  font-size: 4rem;
}
@media (min-width: 1024px) {
  .section-home.section-center .home-title {
    font-size: 6rem;
  }
}

.section-home.section-center .home-btns .btn-line.long-line {
  padding: 0 16px;
}
.section-home.section-center .home-btns .btn-line.long-line .text {
  -webkit-transform: none;
  transform: none;
}
.section-home.section-center .home-btns .btn-line.long-line::after,
.section-home.section-center .home-btns .btn-line.long-line::before {
  left: 50%;
  top: 100%;
  border: none;
  border-left: 2px solid currentColor;
  height: 80px;
  width: 2px;
  margin-left: -1px;
  margin-top: 12px;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
.section-home.section-center .home-btns .btn-line.long-line:active::after,
.section-home.section-center .home-btns .btn-line.long-line:active::before,
.section-home.section-center .home-btns .btn-line.long-line:focus::after,
.section-home.section-center .home-btns .btn-line.long-line:focus::before,
.section-home.section-center .home-btns .btn-line.long-line:hover::after,
.section-home.section-center .home-btns .btn-line.long-line:hover::before {
  left: 50%;
  top: 100%;
  border: none;
  border-left: 2px solid currentColor;
  height: 40px;
  width: 2px;
  margin-left: -1px;
}
.section-home.section-center .home-btns .btn-line.long-line:active .text,
.section-home.section-center .home-btns .btn-line.long-line:focus .text,
.section-home.section-center .home-btns .btn-line.long-line:hover .text {
  -webkit-transform: none;
  transform: none;
}

.section-home-item {
  margin-top: 144px;
  color: #ffffff;
}
.section-home-item .section-wrapper {
  padding-top: 128px;
  padding-bottom: 128px;
}

/* 4.3 Section list / .section-list / .lines-list / .post-list / .section-twoside */
.section-list .item {
  margin-top: 40px;
  margin-bottom: 40px;
}
.section-list .item:first-child {
  margin-bottom: 0;
}
.section-list .item:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .section-list .item > .order-md-1 {
    padding-right: 40px;
  }
}
@media (min-width: 1024px) {
  .section-list .item > .order-md-2 {
    padding-left: 40px;
  }
}

.service-list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .service-list {
    margin: 0.75rem -0.75rem;
  }
}

.product-list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .product-list {
    margin: 0.75rem -0.75rem;
  }
}
.product-list .item {
  padding: 0.75rem;
}

.lines-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 32px;
}
@media (min-width: 1024px) {
  .lines-list {
    margin: 0.75rem 0;
    margin-top: 96px;
    margin-bottom: 96px;
  }
}
.lines-list .item {
  margin: 0.75rem 0;
}

.post-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 32px;
  margin-bottom: 32px;
}
@media (min-width: 1024px) {
  .post-list {
    margin: 0.75rem -0.75rem;
    margin-top: 96px;
    margin-bottom: 96px;
  }
}
.post-list .item {
  padding: 0.75rem;
}

@media (min-width: 1024px) {
  .section-twoside .item > .order-md-1 {
    padding-right: 40px;
  }
}

@media (min-width: 1024px) {
  .section-twoside .item > .order-md-2 {
    padding-left: 40px;
  }
}

.section-twoside {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
}
@media screen and (min-width: 1024px) {
  .section-twoside {
    padding: 0;
  }
}
.section-twoside .section-side {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  width: 100%;
}
.section-twoside .section-side .article {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side {
    padding: 96px 0;
    width: 50%;
    min-height: 100vh;
  }
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side.side-fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
}
.section-twoside .section-side .section-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.section-twoside .section-side .section-wrapper {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .section-twoside .section-side .section-wrapper {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side .section-wrapper {
    max-width: 540px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 1600px) {
  .section-twoside .section-side .section-wrapper {
    max-width: 600px;
  }
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side.side-left .section-wrapper {
    margin-right: 0;
    padding-left: 40px;
    padding-right: 64px;
  }
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side.side-right .section-wrapper {
    margin-left: 0;
    padding-left: 64px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side.side-right.side-scroll {
    margin-left: 50%;
  }
}
.section-twoside .section-side.side-content {
  background: #ffffff;
  color: #1d1d1d;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side.side-content {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 768px) {
  .section-twoside .section-side.side-content .btns-action {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 64px;
    padding-right: 64px;
  }
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side.side-content .btns-action {
    padding: 0;
  }
}
.section-twoside .section-side.side-cover .section-content {
  padding-top: 64px;
  padding-bottom: 64px;
}
@media screen and (min-width: 768px) {
  .section-twoside .section-side.side-cover .section-content {
    padding-top: 128px;
  }
}
@media screen and (min-width: 1024px) {
  .section-twoside .section-side.side-cover .section-content {
    padding: 0;
  }
}
.section-twoside.auto-height .section-side {
  min-height: 100px;
}
