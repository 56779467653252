/* b Clock */
/* 2 Pageheader, Menu navigation dot */
/* 2.1 Page header / .page-header / .page-header-drawer */
.page-header.page-header-gallery {
  position: fixed;
}

@media (min-width: 768px) {
  .page-header.content-white .navbar-topmenu {
    color: #ffffff;
  }
}

/* .page-header.content-black .menu-icon .text {
  color: #1d1d1d; }

.page-header.content-black .menu-icon .bars .bar1, .page-header.content-black .menu-icon .bars .bar2, .page-header.content-black .menu-icon .bars .bar3 {
  background: #1d1d1d; }

.page-header.content-black .menu-icon.menu-visible .text {
  color: #ffffff; }

.page-header.content-black .menu-icon.menu-visible .bars .bar1, .page-header.content-black .menu-icon.menu-visible .bars .bar2, .page-header.content-black .menu-icon.menu-visible .bars .bar3 {
  background: #ffffff; } */

.page-header-drawer {
  justify-content: flex-start;
  z-index: 2;
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  .page-header-drawer {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .page-header-drawer.menu-right .navbar-toggler {
    top: 64px;
    left: auto;
    right: 48px;
  }
}
@media (min-width: 768px) {
  .page-header-drawer.menu-right .navbar-brand {
    left: 48px;
  }
}
@media (min-width: 768px) {
  .page-header-drawer.menu-right .navbar-topmenu {
    right: 120px;
  }
}
.page-header-drawer .navbar-toggler {
  position: fixed;
  top: 32px;
  right: 16px;
  padding: 0;
  border: 0;
  z-index: 999;
  display: block;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.page-header-drawer .navbar-toggler:focus {
  outline: none;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-toggler {
    top: 48px;
    left: 48px;
    right: auto;
  }
}
.page-header-drawer .navbar-brand {
  display: flex;
  flex-direction: row;
  padding: 0;
  transition: 0.3s ease-in-out;
  color: currentColor;
  margin-top: 16px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-brand {
    margin-top: -8px;
    position: absolute;
    left: 120px;
    top: 64px;
    z-index: 20;
  }
}
@media (min-width: 1024px) {
  .page-header-drawer .navbar-brand {
    margin-top: -8px;
    position: fixed;
    left: 120px;
    top: 64px;
    z-index: 20;
  }
}
.page-header-drawer .navbar-brand .logo img {
  height: 40px;
  width: auto;
  margin-right: 16px;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-brand .logo img {
    height: 40px;
  }
}
.page-header-drawer .navbar-brand .text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: currentColor;
  font-size: 1.125rem;
  margin-left: 0;
  margin-right: 8px;
}
.page-header-drawer .navbar-brand .text .line {
  display: block;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  font-size: 1.75rem;
  line-height: 1;
}
.page-header-drawer .navbar-brand .text .line.sub {
  font-family: 'Noto Sans', 'Open Sans', 'Segoe UI', 'Opensans', 'Roboto',
    'Helvetica', -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
  font-size: 0.875rem;
  opacity: 0.5;
  margin-top: 0.5rem;
}
.page-header-drawer .all-menu-wrapper {
  justify-content: flex-end;
  position: fixed;
  z-index: 5;
  background: #ffffff;
  top: -100%;
  left: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 48px 32px;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  min-height: 100vh;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.page-header-drawer .all-menu-wrapper.menu-visible {
  top: 0;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 768px -1px) {
  .page-header-drawer .all-menu-wrapper .navbar-nav .nav-item {
    font-size: 1.5rem;
    opacity: 1;
  }
  .page-header-drawer .all-menu-wrapper .navbar-nav .nav-item a {
    opacity: 0.5;
  }
  .page-header-drawer .all-menu-wrapper .navbar-nav .nav-item a.active {
    color: currentColor;
  }
  .page-header-drawer .all-menu-wrapper .navbar-nav .nav-item a.hover {
    color: #d50b27;
  }
}
@media (min-width: 768px) {
  .page-header-drawer .all-menu-wrapper {
    display: block;
    position: static;
    border-bottom: none;
    box-shadow: none;
    padding: 0;
    top: 0;
    opacity: 1;
    visibility: visible;
    min-height: auto;
  }
}
.page-header-drawer .navbar-topmenu {
  align-items: center;
  transition: 0.3s ease-in-out;
  order: 2;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu {
    display: flex;
    position: fixed;
    top: 48px;
    right: 48px;
    z-index: 10;
    order: initial;
  }
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu .navbar-nav {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu .navbar-nav-actions {
    margin-left: 16px;
  }
}
.page-header-drawer .navbar-topmenu .navbar-nav-actions .nav-item {
  margin-top: 4px;
  margin-bottom: 4px;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu .navbar-nav-actions .nav-item {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 8px;
  }
}
.page-header-drawer .navbar-topmenu .navbar-nav-menu {
  border-top: 1px solid currentColor;
  margin-top: 32px;
  padding-top: 24px;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu .navbar-nav-menu {
    border-top: none;
    margin-top: 0px;
    padding-top: 0px;
  }
}
.page-header-drawer .navbar-topmenu .navbar-nav-menu .nav-item {
  text-transform: uppercase;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  letter-spacing: 0.2em;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu .navbar-nav-menu .nav-item {
    line-height: 1;
    position: relative;
  }
}
.page-header-drawer .navbar-topmenu .navbar-nav-menu .nav-item a {
  color: currentColor;
  opacity: 0.5;
}
.page-header-drawer .navbar-topmenu .navbar-nav-menu .nav-item a:hover {
  opacity: 1;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu .navbar-nav-menu .nav-item a {
    padding: 8px 16px;
    margin-top: -8px;
  }
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu .navbar-nav-menu .nav-item:first-child {
    border-left: none;
  }
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu .navbar-nav-menu .nav-item:last-child {
    margin-right: -16px;
  }
}
.page-header-drawer .navbar-topmenu .navbar-nav-menu .nav-item.active a {
  color: currentColor;
  opacity: 1;
}
.page-header-drawer .navbar-nav-actions {
  transition: 0.3s ease-in-out;
}
.page-header-drawer .navbar-mainmenu {
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-mainmenu {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
  }
}
.page-header-drawer .navbar-mainmenu .mainmenu-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.page-header-drawer .navbar-mainmenu .mainmenu-content {
  margin-left: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  transition: 0.3s;
}
@media (min-width: 1024px) {
  .page-header-drawer .navbar-mainmenu .mainmenu-content {
    padding: 64px 64px;
  }
}
@media (min-width: 1024px) {
  .page-header-drawer .navbar-mainmenu .mainmenu-content {
    background: transparent;
  }
}
.page-header-drawer .navbar-mainmenu .mainmenu-content .navbar-nav {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-header-drawer .navbar-mainmenu .mainmenu-content li,
.page-header-drawer .navbar-mainmenu .mainmenu-content .nav-item {
  line-height: 1.2;
  font-size: 2.5rem;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  font-weight: 300;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-mainmenu .mainmenu-content li,
  .page-header-drawer .navbar-mainmenu .mainmenu-content .nav-item {
    font-size: 4rem;
    font-weight: bold;
  }
}
@media (min-width: 1024px) {
  .page-header-drawer .navbar-mainmenu .mainmenu-content li,
  .page-header-drawer .navbar-mainmenu .mainmenu-content .nav-item {
    font-size: 5rem;
  }
}
.page-header-drawer .navbar-mainmenu .mainmenu-content li a,
.page-header-drawer .navbar-mainmenu .mainmenu-content .nav-item a {
  color: currentColor;
  padding: 0;
  position: relative;
}
.page-header-drawer .navbar-mainmenu .mainmenu-content li a::before,
.page-header-drawer .navbar-mainmenu .mainmenu-content .nav-item a::before {
  position: absolute;
  content: '';
  background: #d50b27;
  top: 50%;
  bottom: 10%;
  left: -10%;
  width: 0%;
  z-index: -1;
  transition: 0.3s;
}
.page-header-drawer .navbar-mainmenu .mainmenu-content li a:hover::before,
.page-header-drawer
  .navbar-mainmenu
  .mainmenu-content
  .nav-item
  a:hover::before {
  width: 120%;
}
.page-header-drawer .navbar-mainmenu .mainmenu-content .navbar-footer {
  position: absolute;
  bottom: 64px;
  margin-bottom: -4px;
  left: 32px;
  right: 32px;
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-mainmenu .mainmenu-content .navbar-footer {
    left: 48px;
    right: 48px;
  }
}
.page-header-drawer .navbar-mainmenu .mainmenu-content .navbar-footer p {
  margin: 0;
  font-size: 0.875rem;
}
.page-header-drawer .navbar-mainmenu .mainmenu-content .navbar-footer .icons {
  position: relative;
  padding-left: 56px;
}
.page-header-drawer
  .navbar-mainmenu
  .mainmenu-content
  .navbar-footer
  .icons:before {
  position: absolute;
  content: '';
  width: 32px;
  top: 50%;
  left: 0;
  border-bottom: 2px solid currentColor;
}
.page-header-drawer
  .navbar-mainmenu
  .mainmenu-content
  .navbar-footer
  .icons
  .btn-icon {
  height: 32px;
}
.page-header-drawer
  .navbar-mainmenu
  .mainmenu-content
  .navbar-footer
  .footer-notes {
  display: flex;
}
@media (min-width: 768px) {
  .page-header-drawer
    .navbar-mainmenu
    .mainmenu-content
    .navbar-footer
    .footer-notes {
    align-items: flex-end;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .page-header-drawer .all-menu-wrapper.menu-visible .navbar-mainmenu {
    left: 0;
    visibility: visible;
    opacity: 1;
  }
  .page-header-drawer
    .all-menu-wrapper.menu-visible
    .navbar-mainmenu
    .mainmenu-content {
    -webkit-transform: none;
    transform: none;
  }
  .page-header-drawer
    .all-menu-wrapper.menu-visible
    .navbar-mainmenu
    .nav-item {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .page-header-drawer .navbar-topmenu:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    transition: 0.3s ease-in-out;
    z-index: -1;
  }
}
@media (max-width: 768px) {
  .page-header-drawer.mobile-absolute .navbar-toggler {
    position: absolute;
  }
  .page-header-drawer.mobile-absolute .all-menu-wrapper {
    position: absolute;
    max-height: none;
  }
}

@media screen and (min-width: 768px) {
  .body-item .page-header-drawer .navbar-toggler,
  .body-item .page-header-drawer .navbar-brand {
    position: absolute;
  }
}

@media screen and (min-width: 1024px) {
  .body-item .page-header-drawer .navbar-toggler,
  .body-item .page-header-drawer .navbar-brand {
    position: fixed;
  }
}

.fp-scrolled.page-header-drawer .navbar-brand {
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 768px) {
  .page-main .section {
    transition: 0.6s;
  }
  .page-main.menu-visible .section {
    opacity: 0.05;
  }
  .navbar-sidebar {
    transition: 0.6s;
  }
  .navbar-sidebar.menu-visible {
    opacity: 0.1;
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }
  .page-cover {
    transition: 0.6s;
  }
  .page-cover.menu-visible {
    -webkit-filter: blur(10px);
    filter: blur(10px);
  }
  .page-header-alpha .navbar-mainmenu {
    background: none;
  }
}

/* 2.2 Menu-icon / .menu-icon */
.menu-icon {
  height: 48px;
  width: 48px;
  transition: 0.3s;
  display: inline-block;
  position: relative;
  opacity: 1;
}
.menu-icon:hover {
  opacity: 1;
}
.menu-icon.menu-bg {
  background: #3f51b5;
}
@media (min-width: 768px) {
  .menu-icon {
    background: none;
  }
}
.menu-icon:after {
  display: none;
}
.menu-icon .text {
  position: absolute;
  text-align: left;
  top: 0px;
  line-height: 1;
  right: 100%;
  padding-right: 16px;
  text-transform: uppercase;
  font-size: 1.125rem;
  color: #1d1d1d;
  transition: 0.3s;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  letter-spacing: 0.2em;
}
.menu-icon .text.hide-menu-visible {
  visibility: visible;
  opacity: 1;
}
.menu-icon .text.show-menu-visible {
  visibility: hidden;
  opacity: 0;
}
.menu-icon .bars {
  display: block;
  margin: auto;
  margin-top: 1px;
  width: 24px;
  transition: 0.3s;
}
.menu-icon .bars .bar1,
.menu-icon .bars .bar2,
.menu-icon .bars .bar3 {
  display: block;
  content: '';
  height: 2px;
  width: 100%;
  margin-bottom: 4px;
  transition: 0.3s;
  background: #1d1d1d;
}
.menu-icon.menu-icon-thick .bars .bar1,
.menu-icon.menu-icon-thick .bars .bar2,
.menu-icon.menu-icon-thick .bars .bar3 {
  height: 4px;
  border-radius: 4px;
}
.menu-icon.menu-icon-random .bars .bar1 {
  width: 20px;
}
.menu-icon.menu-icon-random .bars .bar3 {
  width: 12px;
}
.menu-icon.menu-icon-dot .bars {
  width: 6px;
  margin-top: -6px;
}
.menu-icon.menu-icon-dot .bars .bar1,
.menu-icon.menu-icon-dot .bars .bar2,
.menu-icon.menu-icon-dot .bars .bar3 {
  height: 6px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 4px;
  border-radius: 20px;
}
.menu-icon.menu-icon-line {
  border: none;
  height: 20px;
  width: 32px;
  padding-bottom: 16px;
}
.menu-icon.menu-icon-line .bars {
  margin-top: 6px;
  margin-right: 0;
  width: 32px;
}
.menu-icon.menu-icon-line .bars .bar1,
.menu-icon.menu-icon-line .bars .bar2,
.menu-icon.menu-icon-line .bars .bar3 {
  width: 100%;
}
.menu-icon.menu-icon-line .bars .bar3 {
  opacity: 0;
}
.menu-icon.menu-icon-line .bars .bar2 {
  visibility: hidden;
}
.menu-icon.menu-icon-dual {
  border: none;
  height: 20px;
  width: 40px;
  padding-bottom: 16px;
}
.menu-icon.menu-icon-dual .bars {
  margin-right: 0;
  width: 40px;
}
.menu-icon.menu-icon-dual .bars .bar1,
.menu-icon.menu-icon-dual .bars .bar2,
.menu-icon.menu-icon-dual .bars .bar3 {
  width: 100%;
}
.menu-icon.menu-icon-dual .bars .bar2 {
  visibility: hidden;
}
.menu-icon.menu-visible .text.show-menu-visible {
  visibility: visible;
  opacity: 1;
}
.menu-icon.menu-visible .text.hide-menu-visible {
  visibility: hidden;
  opacity: 0;
}
.menu-icon.menu-visible .bars {
  width: 40px;
  margin-top: 0px;
}
.menu-icon.menu-visible .bars .bar1,
.menu-icon.menu-visible .bars .bar2,
.menu-icon.menu-visible .bars .bar3 {
  height: 2px;
  width: 100%;
  margin-bottom: 4px;
  border-radius: 0;
}
.menu-icon.menu-visible .bars .bar1 {
  -webkit-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
}
.menu-icon.menu-visible .bars .bar3 {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  opacity: 1;
}
.menu-icon.menu-visible .bars .bar2 {
  width: 0px;
}
.menu-icon.menu-visible.menu-icon-twobar .bars {
  margin-top: 0;
  margin-right: 0;
}

/* 2.3 Navigation dot / #fp-nav */
@media screen and (max-width: 1024px) {
  #fp-nav {
    visibility: hidden;
  }
}

#fp-nav.content-white {
  color: #ffffff;
}

#fp-nav.content-black {
  color: #1d1d1d;
}

#fp-nav.right {
  right: 18px;
  margin-right: 0px;
  position: fixed;
}
#fp-nav.right a {
  color: currentColor;
}

#fp-nav ul li,
.fp-slidesNav ul li {
  margin: 6px 0;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  width: 4px;
  height: 4px;
  border-radius: 40px;
  background: currentColor;
  opacity: 0.2;
  transition: 0.3s;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 12px;
  height: 12px;
  border-radius: 40px;
  margin: -6px 0 0 -6px;
  background: currentColor;
  opacity: 1;
}

/* 3 Page / .page-main , .body-page */
/* 3.1 Initialization */
/* 4.1 Default settings .page-main */
.page-main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.page-main.fullpage-container {
  display: block;
}

.body-page {
  background-color: #ffffff;
  color: #1d1d1d;
}

.body-item {
  background-color: #ffffff;
  color: #1d1d1d;
}

/* 4.2 Default settings .page-fullpage */
.body-page {
  position: relative;
}
.body-page .cursor-ball {
  position: absolute;
  z-index: 0;
  content: '';
  width: 50vw;
  height: 100vh;
  margin-left: -25vw;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.05);
  transition: 0.05s ease-in-out;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

/* 4.3 Default settings .page-post */
/* 3.2 Page header / .page-header */
/* 3.3 Page footer / page-footer */
.page-footer {
  transition: 0.3s;
}
.page-footer.content-white {
  color: #ffffff;
}
.page-footer.content-black {
  color: #1d1d1d;
}
.page-footer .footer-left,
.page-footer .footer-right {
  transition: 0.3s;
}
.page-footer .footer-left a,
.page-footer .footer-right a {
  color: currentColor;
}
.page-footer .footer-left p,
.page-footer .footer-right p {
  font-size: 0.875rem;
  color: currentColor;
  margin-bottom: 0;
}
.page-footer .footer-left p .marked,
.page-footer .footer-right p .marked {
  font-weight: bold;
}
.page-footer .footer-left {
  position: fixed;
  left: 16px;
  z-index: 1;
  bottom: 8px;
}
@media (min-width: 768px) {
  .page-footer .footer-left {
    left: 48px;
    bottom: 64px;
  }
}
.page-footer .footer-left .note {
  -webkit-transform: rotate(-90deg) translateY(100%);
  transform: rotate(-90deg) translateY(100%);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-footer .footer-left .social {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-left: -8px;
  margin-right: 16px;
  position: relative;
  padding: 0;
}
.page-footer .footer-left .social:after {
  content: '';
  width: 32px;
  height: 2.3px;
  background: #d50b27;
  margin-top: 0px;
  margin-left: 8px;
}
.page-footer .footer-left .social a {
  padding: 0 8px;
  display: inline-block;
  color: currentColor;
  opacity: 0.5;
}
.page-footer .footer-left .social a:hover {
  opacity: 1;
  color: #d50b27;
}
.page-footer .footer-left .social .icon {
  font-size: 32px;
}
.page-footer .footer-right {
  position: fixed;
  bottom: 8px;
  right: 16px;
  z-index: 1;
}
@media (min-width: 768px) {
  .page-footer .footer-right {
    right: 48px;
    bottom: 44px;
  }
}
.page-footer .footer-right .note {
  display: inline-block;
}
.page-footer.scrolled .footer-left,
.page-footer.scrolled .footer-right {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
}
.page-footer.fp-scrolled {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 1200px) {
  .page-footer.fp-scrolled {
    visibility: visible;
    opacity: 1;
  }
}

.body-page.menu-visible .footer-left,
.body-page.menu-visible .footer-right,
.page-footer.scrolled .footer-left,
.page-footer.scrolled .footer-right {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
}
