/* 5.2 Helper classes */
.display-1 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1;
}
@media (min-width: 768px) {
  .display-1 {
    font-size: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 6rem;
  }
}

.display-2 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 5.5rem;
  }
}

.display-3 {
  font-size: 2rem !important;
  line-height: 1.1;
  font-weight: 400 !important;
}
@media (min-width: 1024px) {
  .display-3 {
    font-size: 3.5rem !important;
  }
}

.display-4 {
  font-size: 2.5rem;
  line-height: 1.1;
  font-weight: 600;
}
@media (min-width: 768px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 4rem;
  }
}

.display-5 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.5rem;
  }
}

.display-6 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.1;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.75rem;
  }
}

.display-6 {
  font-size: 1.5rem;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.75rem;
  }
}

.display-title {
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  font-weight: bold;
  line-height: 1.1;
}
.display-title .light,
.display-title .lite {
  opacity: 0.5;
}

@media (min-width: 1024px) {
  .display-text-big p {
    font-size: 2.5rem;
  }
}

.title-margin {
  margin-bottom: 2rem;
}

.light,
.lite {
  opacity: 0.5;
}

.display-condensed {
  font-family: 'Roboto Condensed', 'Bebas Neue', 'Ostrich Sans', 'Oswald',
    sans-serif;
  text-transform: uppercase;
}

.display-geo {
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
}

.text-stroke {
  -webkit-text-stroke: 1.5px #1d1d1d;
  color: transparent;
}

.text-stroke.text-primary {
  -webkit-text-stroke: 1.5px #d50b27;
  color: transparent !important;
}

/* .content-white .text-stroke {
  -webkit-text-stroke: 1.5px #ffffff; } */

.content-black .text-stroke {
  -webkit-text-stroke: 1.5px #1d1d1d;
}

.text-no-shadow {
  text-shadow: none !important;
}

.display-decor {
  position: relative;
  margin-top: 2rem;
  padding-top: 1rem;
}
.display-decor:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  border-bottom: 2px solid currentColor;
  width: 80px;
}

.display-subtitle {
  text-transform: uppercase;
  font-family: 'Jost', 'Glacial Indifference', 'Montserrat', 'Open Sans',
    sans-serif;
  letter-spacing: 0.2em;
  font-size: 1rem;
}

.display-normal {
  font-size: 1rem;
}

@media (max-width: 575px) {
  .text-right-sm {
    text-align: right !important;
  }
}

@media (max-width: 575px) {
  .text-left-sm {
    text-align: left !important;
  }
}

@media (max-width: 575px) {
  .text-center-sm {
    text-align: center !important;
  }
}

.text-color-normal {
  color: inherit;
}
.text-color-normal:hover {
  color: inherit;
}

.strong,
strong {
  font-weight: bold;
}

.condensed {
  font-family: 'Roboto Condensed', 'Bebas Neue', 'Ostrich Sans', 'Oswald',
    sans-serif;
}

h1,
h2,
h3,
h4 {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

p {
  font-size: 1rem;
  line-height: 1.8;
}
@media (min-width: 768px) {
  p {
    font-size: 1.125rem;
  }
}
@media (min-width: 1024px) {
  p {
    font-size: 1.5rem;
  }
}

.font-primary {
  font-family: 'Roboto Condensed', 'Bebas Neue', 'Ostrich Sans', 'Oswald',
    sans-serif;
}

.font-secondary {
  font-family: 'Noto Sans', 'Open Sans', 'Segoe UI', 'Opensans', 'Roboto',
    'Helvetica', -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
}

/* 5.3 Responsiveness */
.text-black {
  color: #1d1d1d !important;
}

.text-primary {
  color: #d50b27 !important;
}

.text-secondary {
  color: #3f51b5 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-black {
  color: #1d1d1d !important;
}
